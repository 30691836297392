import gql from 'graphql-tag';
import moment from 'moment';
import { apolloClientAuth } from './apolloClient';

const meKey = 'auth-me';
const tokenKey = 'auth-token';

const SIGNIN = gql`
    mutation Signin($email: String!, $password: String!) {
        signinEmail: signinEmail(input: { email: $email, password: $password }) {
            token {
                token
                expiresAt
            }
            refresh {
                token
                expiresAt
            }
            me {
                email
                isAdmin
            }
        }
    }
`;

export default {
  login: ({ username, password }) => apolloClientAuth().mutate({
    mutation: SIGNIN,
    variables: {
      email: username,
      password,
    },
  }).then((response) => {
    const {
      data: {
        signinEmail: { me, token, refresh },
      },
    } = response;
    if (!me.isAdmin) {
      throw new Error('Staff only');
    }
    localStorage.setItem(meKey, JSON.stringify(me));
    localStorage.setItem(tokenKey, JSON.stringify({ token, refresh }));
    return Promise.resolve();
  }).catch((error) => Promise.reject(error)),
  logout: () => {
    localStorage.removeItem(meKey);
    localStorage.removeItem(tokenKey);
    return Promise.resolve();
  },
  checkError: (error) => {
    const { status } = error;
    if (status === 401 || status === 403) {
      localStorage.removeItem(meKey);
      localStorage.removeItem(tokenKey);
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    const authToken = JSON.parse(localStorage.getItem(tokenKey));
    if (!authToken) {
      return Promise.reject();
    }
    const expiresAt = moment(authToken.token.expiresAt);
    if (expiresAt.isBefore(moment())) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
};
