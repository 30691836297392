import moment from 'moment';

export const querySortHelper = ({ field, order }) => {
  const key = field === 'id' ? 'createdAt' : field;
  return {
    [key]: true,
    [`${key}Desc`]: (order === 'DESC'),
  };
};

export const cleanUpSortFields = (field) => {
  const sortFieldMap = {
    [field]: field,
  };
  return sortFieldMap[field];
};

export const createdAfterFilter = {
  day: moment()
    .subtract(1, 'day')
    .toDate(),
  week: moment()
    .subtract(1, 'week')
    .toDate(),
  month: moment()
    .subtract(1, 'month')
    .toDate(),
  year: moment()
    .subtract(1, 'year')
    .toDate(),
};
