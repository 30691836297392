import { earnersQuery } from './resources/earners';
import { payoutsQuery } from './resources/payouts';
import { devicesQuery } from './resources/devices';

const buildQuery = () => (queryType, resourceName, params) => {
  switch (resourceName) {
    case 'earners':
      return earnersQuery({ queryType, params });
    case 'payouts':
      return payoutsQuery({ queryType, params });
    case 'devices':
      return devicesQuery({ queryType, params });
    default:
      throw new Error(`Unknown resource ${resourceName}`);
  }
};

export default buildQuery;
