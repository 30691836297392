import React from 'react';
import { Layout, Notification, Sidebar } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';

export default ({ children, ...props }) => (
  <Layout
    {...props}
    appBar={AppBar}
    menu={Menu}
    sidebar={Sidebar}
    notification={Notification}
  >
    {children}
  </Layout>
);
