import React, { useState, useEffect } from 'react';
import { Admin, Resource } from 'react-admin';
import {
  Dialog, DialogContent, DialogTitle, LinearProgress,
} from '@material-ui/core';
import authProvider from './authProvider';
import buildDataProvider from './buildDataProvider';
import { Layout, LoginPage } from './components';
import { EarnersList, EarnersShow } from './resources/earners';
import { PayoutsList } from './resources/payouts';
import { DevicesList } from './resources/devices';
import theme from './theme';
import Dashboard from './components/Dashboard';

const Loading = () => (
  <Dialog open fullWidth>
    <DialogTitle>Loading</DialogTitle>
    <DialogContent>
      <LinearProgress variant="indeterminate" />
    </DialogContent>
  </Dialog>
);

export default () => {
  const [dataProvider, setDataProvider] = useState(null);

  useEffect(() => {
    buildDataProvider().then((dp) => {
      setDataProvider(() => dp);
    });
  }, []);

  return !authProvider || !dataProvider ? (
    <Loading />
  ) : (
    <Admin
      theme={theme}
      authProvider={authProvider}
      dataProvider={dataProvider}
      layout={Layout}
      loginPage={LoginPage}
      dashboard={Dashboard}
    >
      <Resource name="earners" list={EarnersList} show={EarnersShow} />
      <Resource name="payouts" list={PayoutsList} />
      <Resource name="devices" list={DevicesList} />
    </Admin>
  );
};
