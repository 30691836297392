import React from 'react';
import get from 'lodash/get';
import { Link, Typography } from '@material-ui/core';

const BetterURLField = ({ record = {}, refSource, valSource }) => {
  const ref = get(record, refSource);
  const value = get(record, valSource);
  if (!value) {
    return (
      <Typography
        component="span"
        variant="body2"
      />
    );
  }

  return (
    <Link
      href={ref}
      variant="body2"
      target="_blank"
      rel="noopener noreferrer"
    >
      {value || ref}
    </Link>
  );
};

export default BetterURLField;
