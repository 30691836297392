import React from 'react';
import gql from 'graphql-tag';
import {
  Button, CircularProgress, Grid, Typography,
} from '@material-ui/core';
import Icon from '@material-ui/icons/Face';
import get from 'lodash/get';
import { useQuery } from '@apollo/client';

const USER_DETAILS = gql`
    query ($id: Int64!) {
        object: usersDetail(id: $id) {
            user {
                impersonateURL
            }
        }
    }
`;

const Progress = () => (
  <Grid container spacing={2} direction="column" justify="center" alignItems="center" style={{ height: `${100}vh` }}>
    <Grid item>
      <CircularProgress variant="indeterminate" />
    </Grid>
    <Grid item>
      <Typography align="center">impersonating...</Typography>
    </Grid>
  </Grid>
);

const Impersonate = ({ query, id }) => {
  const { loading, error, data } = useQuery(query, { variables: { id } });
  if (!loading && !error && !!data) {
    const { object: { user: { impersonateURL } } } = data;
    window.location.href = impersonateURL;
  }

  return <Progress />;
};

export const ImpersonateUser = ({ match: { params: { id } } }) => (
  <Impersonate query={USER_DETAILS} id={id} />
);

export const ImpersonateButton = ({ record, source }) => {
  if (!record) {
    return null;
  }

  const recordId = get(record, source ? `${source}.id` : 'id');
  return (
    <span>
      <Button component="a" href={`/impersonate/${recordId}`} target="_blank">
        <Icon color="primary" />
      </Button>
    </span>
  );
};

ImpersonateButton.defaultProps = {
  addLabel: false,
  type: 'account',
};
