import React, { useState } from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import { Button, CardActions, CircularProgress } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import UpdatePaymentConfigModal from './UpdatePaymentConfig';
import PaymentsConfig from './PaymentsConfig';

const query = gql`
    query PaymentsConfig {
        paymentConfig {
            pricePerGiB
            payoutGoal
            referralPercentages
            onlineRewards
        }
    }
`;

const PaymentCard = ({ classes }) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const { loading, error, data } = useQuery(query);
  if (loading || error) {
    return <CircularProgress variant="indeterminate" />;
  }

  const { paymentConfig } = data;

  return (
    <Card>
      <CardHeader title="Payments pricing" />
      <CardContent>
        <UpdatePaymentConfigModal
          paymentConfig={paymentConfig}
          open={editModalOpen}
          handleClose={() => setEditModalOpen(false)}
          refetchQueries={[{ query }]}
        />
        <PaymentsConfig paymentConfig={paymentConfig} />
      </CardContent>
      <CardActions className={classes.actions}>
        <Button size="small" onClick={() => setEditModalOpen(true)}><Edit /></Button>
      </CardActions>
    </Card>
  );
};

const styles = {
  actions: {
    float: 'right',
  },
};

export default withStyles(styles)(PaymentCard);
