import red from '@material-ui/core/colors/red';
import get from 'lodash/get';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import blue from '@material-ui/core/colors/blue';

export const getRowStyle = (record) => {
  const status = get(record, 'payoutStatus', '').toLowerCase();
  if (status === 'rejected') {
    return {
      borderLeftColor: red[200],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  } if (status === 'paid') {
    return {
      borderLeftColor: green[50],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  } if (status === 'processing') {
    return {
      borderLeftColor: green[400],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  } if (status === 'approved') {
    return {
      borderLeftColor: orange[400],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  } if (status === 'pending') {
    return {
      borderLeftColor: blue[400],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  }

  return {};
};
