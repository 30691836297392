import React from 'react';
import { BulkUpdateButton } from 'react-admin';
import {
  Check,
  Autorenew,
  Payment,
  Cancel,
} from '@material-ui/icons';

const ApproveButton = (props) => (
  <BulkUpdateButton
    {...props}
    label="Approve (auto process)"
    data={{ status: 'APPROVED' }}
    icon={<Check />}
  />
);

const MarkPaidButton = (props) => (
  <BulkUpdateButton
    {...props}
    label="Mark Paid"
    data={{ status: 'PAID' }}
    icon={<Payment />}
  />
);

const MarkPendingButton = (props) => (
  <BulkUpdateButton
    {...props}
    label="Mark Pending"
    data={{ status: 'PENDING' }}
    icon={<Autorenew />}
  />
);

const RejectButton = (props) => (
  <BulkUpdateButton
    {...props}
    label="Reject"
    data={{ status: 'REJECTED' }}
    icon={<Cancel />}
  />
);

export default (props) => (
  <>
    <ApproveButton {...props} />
    <MarkPaidButton {...props} />
    <MarkPendingButton {...props} />
    <RejectButton {...props} />
  </>
);
