import React from 'react';
import { List } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { MenuItemLink } from 'react-admin';
import {
  People as EarnersIcon,
  Payment as PaymentIcon,
  Dashboard as DashboardIcon,
  Devices as DevicesIcon,
} from '@material-ui/icons';

const Menu = ({ onMenuClick }) => (
  <List>
    <MenuItemLink
      to="/"
      primaryText="Dashboard"
      leftIcon={<DashboardIcon />}
      onClick={onMenuClick}
    />
    <MenuItemLink
      to="/earners"
      primaryText="Earners"
      leftIcon={<EarnersIcon />}
      onClick={onMenuClick}
    />
    <MenuItemLink
      to="/devices"
      primaryText="Devices"
      leftIcon={<DevicesIcon />}
      onClick={onMenuClick}
    />
    <MenuItemLink
      to="/payouts"
      primaryText="Payout Requests"
      leftIcon={<PaymentIcon />}
      onClick={onMenuClick}
    />
  </List>
);

const style = {
  multiLineMenuItem: {
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
};

export default withStyles(style)(Menu);
