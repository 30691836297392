import { GET_LIST, GET_MANY_REFERENCE } from 'react-admin';
import gql from 'graphql-tag';
import { cleanUpSortFields, querySortHelper } from '../utils';

const getList = ({ pagination: { page, perPage }, filter, sort }) => {
  // eslint-disable-next-line no-param-reassign
  sort.field = cleanUpSortFields([sort.field]);
  const variables = {
    limit: perPage,
    offset: (page - 1) * perPage,
    f: filter,
    s: querySortHelper(sort),
  };
  return {
    query: gql`
        query devicesSlice($limit: Int!, $offset: Int!, $f: DeviceSliceFilter, $s: DeviceSliceSorter) {
            devicesSlice(limit: $limit, offset: $offset, f: $f, s: $s) {
                total: totalCount
                data: items {
                  id
                  createdAt
                  updatedAt
                  lastSeen
                  identifier
                  machineID
                  platform
                  ip
                  ipInfoURL
                  userID
                  bandwidthSold
                }
            }
        }
    `,
    variables,
    parseResponse: ({ data: { devicesSlice: { data, total } } }) => ({ data, total }),
  };
};

const getManyReference = ({
  target, sort, id: user, pagination,
}) => {
  const variables = {
    sort: { createdAt: true, createdAtDesc: true, ...sort },
    filter: { user },
  };
  switch (target) {
    case 'earner':
      return getList({ pagination, ...variables });
    default:
      throw new Error(`not implemented: GET_MANY_REFERENCE for ${target}`);
  }
};

export default ({ queryType, params, resource }) => {
  switch (queryType) {
    case GET_MANY_REFERENCE:
      return getManyReference({ ...params, resource });
    case GET_LIST:
      return getList({ ...params });
    default:
      throw new Error(`not implemented: earners ${queryType}`);
  }
};
