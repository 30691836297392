import { createMuiTheme, defaultTheme } from 'react-admin';
import merge from 'lodash/merge';
import lightBlue from '@material-ui/core/colors/lightBlue';
import lightGreen from '@material-ui/core/colors/lightGreen';
import red from '@material-ui/core/colors/red';

export default createMuiTheme(merge({}, defaultTheme, {
  palette: {
    primary: lightBlue,
    secondary: lightGreen,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
  },
}));
