import gql from 'graphql-tag';
import { GET_LIST, GET_MANY, GET_ONE } from 'react-admin';
import { cleanUpSortFields, createdAfterFilter, querySortHelper } from '../utils';

const getOneQuery = gql`
  query UsersDetails($id: Int64!) {
    usersDetail(id: $id) {
      id
      balance
      referralBalance
      bandwidthSold
      referredUsers
      devices
      user {
        id
        handle
        email
        name
      }
    }
  }
`;

const getOne = ({ params: { id } }) => ({
  query: getOneQuery,
  variables: { id },
  parseResponse: ({ data: { usersDetail } }) => ({ data: usersDetail }),
});

const USERS_DETAILS_Q = gql`
        query usersDetailSlice($limit: Int!, $offset: Int!, $f: UsersDetailSliceFilter, $s: UsersDetailSliceSorter) {
            usersDetailSlice(limit: $limit, offset: $offset, f: $f, s: $s) {
                total: totalCount
                data: items {
                    id
                    balance
                    referralBalance
                    bandwidthSold
                    referredUsers
                    userID
                    devices
                    user {
                        id
                        createdAt
                        handle
                        email
                    }
                }
            }
        }
    `;

const getList = ({
  pagination: {
    page,
    perPage,
  },
  filter: {
    q: query,
    createdAfter,
    hasDevices,
  },
  sort,
}) => {
  const sortParam = sort;
  if (sortParam.field.indexOf('.') !== -1) {
    sortParam.field = sort.field.split('.')
      .pop();
  }

  sortParam.field = cleanUpSortFields([sortParam.field]);
  const variables = {
    limit: perPage,
    offset: (page - 1) * perPage,
    f: {
      query,
      createdAfter: createdAfterFilter[createdAfter],
      hasDevices,
    },
    s: querySortHelper(sort),
  };
  return {
    query: USERS_DETAILS_Q,
    variables,
    parseResponse: ({
      data: {
        usersDetailSlice: {
          data,
          total,
        },
      },
    }) => ({
      data,
      total,
    }),
  };
};

const getMany = ({ ids }) => {
  console.log(JSON.stringify(ids));
  const variables = {
    f: {
      ids: [...ids],
    },
    limit: ids.length,
    offset: 0,
  };

  return {
    variables,
    query: USERS_DETAILS_Q,
    parseResponse: ({ data: { usersDetailSlice: { data } } }) => ({
      data,
    }),
  };
};

export default ({
  queryType,
  params,
  resource,
}) => {
  switch (queryType) {
    case GET_MANY:
      return getMany({
        ...params,
        resource,
      });
    case GET_LIST:
      return getList({ ...params });
    case GET_ONE:
      return getOne({
        params,
        resource,
      });
    default:
      throw new Error(`not implemented: earners ${queryType}`);
  }
};
