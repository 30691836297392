import React, { useState } from 'react';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import {
  SaveButton, NumberInput,
} from 'react-admin';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const UPDATE_CONFIG = gql`
    mutation UpdatePaymentConfig($input: PaymentConfigInput!) {
        updatePaymentConfig(conf: $input) {
            pricePerGiB
        }
    }
`;

const UpdatePaymentConfigModal = ({
  refetchQueries, open, handleClose, paymentConfig,
}) => {
  const [loading, setLoading] = useState(false);
  const [updateConfig] = useMutation(UPDATE_CONFIG);
  const {
    pricePerGiB, payoutGoal, referralPercentages, onlineRewards,
  } = paymentConfig;
  const quantileSize = ((payoutGoal / onlineRewards.length) / payoutGoal) * 100;

  const onSubmit = ({
    pricePerGiB: gibPrice,
    payoutGoal: payout,
    onlineRewards: onlineBonuses,
    levels,
  }) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const input = {
      pricePerGiB: gibPrice,
      payoutGoal: payout,
      onlineRewards: onlineBonuses,
      referralPercentages: levels,
    };
    updateConfig({
      variables: { input },
      refetchQueries,
    }).finally(() => {
      setLoading(false);
      handleClose();
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      open={open}
    >
      <Form
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        render={(({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>Adjust payments config</DialogTitle>
            <DialogContent>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12} lg={6} md={6}>
                  <NumberInput
                    defaultValue={payoutGoal}
                    source="payoutGoal"
                    label="Payout Goal (in $)"
                    step={1}
                    max={50}
                    min={1}
                    fullWidth
                  />
                  <NumberInput
                    defaultValue={pricePerGiB}
                    source="pricePerGiB"
                    label="Price Per GiB (in $)"
                    step={0.01}
                    max={1}
                    min={0.15}
                    fullWidth
                  />
                  {onlineRewards.map((bonus, idx) => (
                    <NumberInput
                      defaultValue={bonus}
                      source={`onlineRewards[${idx}]`}
                      label={`Online reward for revenue < ${(idx + 1) * quantileSize}% in $`}
                      step={1}
                      fullWidth
                    />
                  ))}
                </Grid>
                <Grid item xs={12} lg={6} md={6}>
                  {referralPercentages.map((perc, idx) => (
                    <NumberInput
                      defaultValue={perc}
                      source={`levels[${idx}]`}
                      label={`Percentage for level ${idx + 1}`}
                      step={1}
                      fullWidth
                    />
                  ))}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>
                <Typography>Cancel</Typography>
              </Button>
              <SaveButton loading={loading} handleSubmitWithRedirect={handleSubmit} />
            </DialogActions>
          </form>
        ))}
      />
    </Dialog>
  );
};

export default UpdatePaymentConfigModal;
