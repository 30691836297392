import React from 'react';
import {
  Grid, Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import {
  NumberField,
  TextField,
} from 'react-admin';
import CurrencyField from '../../CurrencyField';
import FilesizeField from '../../FilesizeField';

export default ({ record }) => (
  <Grid container spacing={2}>
    <Grid item md={6} xs={12}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>User Information</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Handle</TableCell>
            <TableCell>
              <TextField record={record} source="user.handle" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>
              <TextField record={record} source="user.email" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Registered Devices</TableCell>
            <TableCell>
              <NumberField record={record} source="devices" />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
    <Grid item md={6} xs={12}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>Payment Information</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Balance</TableCell>
            <TableCell>
              <CurrencyField record={record} source="balance" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Made with Referrals</TableCell>
            <TableCell>
              <CurrencyField record={record} source="referralBalance" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Bandwidth Sold</TableCell>
            <TableCell>
              <FilesizeField record={record} source="bandwidthSold" />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  </Grid>
);
