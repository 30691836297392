import gql from 'graphql-tag';
import { GET_LIST, UPDATE_MANY } from 'react-admin';
import { querySortHelper } from '../utils';

const cleanUpSortFields = (field) => {
  const sortFieldMap = {
    [field]: field,
  };
  return sortFieldMap[field];
};

const getList = ({ pagination: { page, perPage }, filter: { status }, sort }) => {
  // eslint-disable-next-line no-param-reassign
  sort.field = cleanUpSortFields([sort.field]);

  const variables = {
    limit: perPage,
    offset: (page - 1) * perPage,
    f: { status },
    s: querySortHelper(sort),
  };
  return {
    query: gql`
        query payoutRequestSlice(
            $limit: Int!, $offset: Int!, 
            $f: PayoutRequestSliceFilter, $s: PayoutRequestSliceSorter) {
            payoutRequestSlice(limit: $limit, offset: $offset, f: $f, s: $s) {
                total: totalCount
                data: items {
                    id
                    createdAt
                    amount
                    payoutStatus
                    user {
                        id
                        handle
                        paypalEmail
                    }
                    team {
                        id
                        name
                    }
                }
            }
        }
    `,
    variables,
    parseResponse: ({ data: { payoutRequestSlice: { data, total } } }) => ({ data: data || [], total }),
  };
};

const updateMany = ({ ids, data: input }) => ({
  query: gql`
      mutation($ids: [Int64!], $status: PayoutRequestStatus!) {
          data: updatePayoutRequests(ids: $ids, status: $status) {
              id
          }
      }
  `,
  variables: { ids, status: input.status },
  parseResponse: ({ data: { data } }) => ({ data }),
});

export default ({ queryType, params }) => {
  switch (queryType) {
    case UPDATE_MANY:
      return updateMany({ ...params });
    case GET_LIST:
      return getList({ ...params });
    default:
      throw new Error(`not implemented: payouts ${queryType}`);
  }
};
