import React from 'react';
import {
  List, Datagrid, Pagination, NumberField, TextField, Filter, TextInput, DateField, SelectInput, NullableBooleanInput,
} from 'react-admin';
import FilesizeField from '../FilesizeField';
import CurrencyField from '../CurrencyField';
import { ImpersonateButton } from '../../components/impersonate';

const EarnersPagination = (props) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

const F = ({ ...props }) => (
  <Filter {...props} variant="standard">
    <TextInput alwaysOn resettable source="q" label="Search" />
    <SelectInput
      source="createdAfter"
      alwaysOn
      label="Created In Last"
      choices={[
        { id: 'day', name: 'Last 24-hours' },
        { id: 'week', name: 'Last 7-days' },
        { id: 'month', name: 'Last 30-days' },
        { id: 'year', name: 'Last 12-months' },
      ]}
    />
    <NullableBooleanInput label="With Devices" source="hasDevices" alwaysOn />
  </Filter>
);

export default ({ ...props }) => (
  <List
    {...props}
    bulkActionButtons={false}
    sort={{ field: 'createdAt', order: 'ASC' }}
    filters={<F />}
    pagination={<EarnersPagination />}
    perPage={25}
  >
    <Datagrid rowClick="show">
      <NumberField sortable source="id" />
      <DateField sortable source="user.createdAt" label="Signup" />
      <TextField source="user.email" label="Email" addLabel />
      <CurrencyField label="Balance" source="balance" />
      <CurrencyField label="Referrals" source="referralBalance" />
      <FilesizeField label="Bandwidth Sold" source="bandwidthSold" />
      <NumberField label="Users Referred" source="referredUsers" />
      <NumberField label="Devices" source="devices" />
      <ImpersonateButton />
    </Datagrid>
  </List>
);
