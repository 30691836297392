import React from 'react';
import {
  Show,
  TabbedShowLayout,
  Tab,
} from 'react-admin';
import Summary from './Summary';
import Devices from './Devices';

export default ({ ...props }) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <Summary />
      </Tab>
      <Tab label="Devices">
        <Devices />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
