import React from 'react';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { apolloClientStaff } from './apolloClient';
import Admin from './Admin';
import { ImpersonateUser } from './components/impersonate';

function App() {
  return (
    <BrowserRouter>
      <ApolloProvider client={apolloClientStaff()}>
        <Switch>
          <Route exact path="/impersonate/:id" component={ImpersonateUser} />
          <Route path="/" component={Admin} />
        </Switch>
      </ApolloProvider>
    </BrowserRouter>
  );
}

export default App;
