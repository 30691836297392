import React from 'react';
import {
  Datagrid, DateField, List, NumberField, Pagination, ReferenceField, TextField,
} from 'react-admin';
import BetterURLField from '../../components/BetterURLField';
import FilesizeField from '../FilesizeField';

export default ({
  record,
  ...props
}) => (
  <List
    {...props}
    bulkActionButtons={false}
    sort={{
      field: 'createdAt',
      order: 'ASC',
    }}
    pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
    perPage={25}
  >
    <Datagrid>
      <TextField source="identifier" sortable={false} />
      <BetterURLField
        valSource="ip"
        refSource="ipInfoURL"
        label="IP"
        sortable={false}
      />
      <FilesizeField source="bandwidthSold" label="Bandwidth Sold" />
      <ReferenceField label="User" source="userID" reference="earners" link="show" sortable={false}>
        <NumberField source="user.email" />
      </ReferenceField>
      <DateField source="createdAt" label="Registered" />
      <DateField source="lastSeen" label="Last Seen" />
    </Datagrid>
  </List>
);
