import React from 'react';
import Grid from '@material-ui/core/Grid';
import PaymentCard from '../Payments/PaymentCard';

const Dashboard = () => (
  <Grid container spacing={2}>
    <Grid item md={10} sm={12}>
      <PaymentCard />
    </Grid>
  </Grid>
);

export default Dashboard;
