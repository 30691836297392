import fetch from 'isomorphic-fetch';
import moment from 'moment';
import {
  ApolloClient, ApolloLink, from, HttpLink, InMemoryCache,
} from '@apollo/client';
import { onError as onApolloError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { getAPIUrl } from './config';

const GRAPHQL_URL = getAPIUrl();

const apolloClient = (uri) => {
  const httpLink = new HttpLink({ uri, fetch });

  const authMiddleware = new ApolloLink((operation, forward) => {
    const auth = JSON.parse(localStorage.getItem('auth-token') || '{"token": null}');
    if (auth && auth.token) {
      const expiresAt = moment(auth.token.expiresAt);
      if (moment().isBefore(expiresAt)) {
        operation.setContext({
          headers: {
            Authorization: `Bearer ${auth.token.token}`,
          },
        });
      }
    }
    return forward(operation);
  });

  const errorMiddleware = onApolloError(({ networkError }) => {
    if (networkError) {
      const { response } = networkError;
      if (!!response && (response.status === 401 || response.status === 403)) {
        localStorage.removeItem('me');
        localStorage.removeItem('auth-token');
        localStorage.removeItem('refresh-token');
      }
    }
  });

  const retryLink = new RetryLink({ attempts: { max: 3 } });
  const link = from([authMiddleware, retryLink, errorMiddleware, httpLink]);
  const cache = new InMemoryCache();
  return new ApolloClient({ link, cache });
};

export const apolloClientAuth = () => apolloClient(`${GRAPHQL_URL}/gql/auth/`);
export const apolloClientStaff = () => apolloClient(`${GRAPHQL_URL}/gql/staff/`);
