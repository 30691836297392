import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { formatMoney } from '../../format';

const PaymentsConfig = ({ paymentConfig }) => {
  const {
    pricePerGiB, payoutGoal, referralPercentages, onlineRewards,
  } = paymentConfig;
  const quantileSize = ((payoutGoal / onlineRewards.length) / payoutGoal) * 100;
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell align="left"><b>Price Per GiB</b></TableCell>
          <TableCell align="right">
            {`${pricePerGiB * 1000} CR (${formatMoney(pricePerGiB, 2)})`}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left"><b>Payout Goal</b></TableCell>
          <TableCell align="right">
            {`${payoutGoal * 1000} CR (${formatMoney(payoutGoal, 2)})`}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left"><b>Hourly Reward in % of payout</b></TableCell>
          <TableCell align="right">
            {`${onlineRewards.map((r, idx) => `${r * 1000} CR < ${(idx + 1) * quantileSize}%`).join(', ')}`}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left"><b>Referral Percentages</b></TableCell>
          <TableCell align="right">{`${referralPercentages.join('%, ')}%`}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default PaymentsConfig;
