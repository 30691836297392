import React from 'react';
import { NumberField } from 'react-admin';

export default ({
  suffix = '', options = {}, style = { display: 'inline' }, ...props
}) => (
  <>
    <NumberField
      {...props}
      options={{
        style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', ...options,
      }}
      style={style}
    />
    {suffix}
  </>
);
