import React from 'react';
import {
  Datagrid, DateField, TextField, ReferenceManyField, Pagination,
} from 'react-admin';
import FilesizeField from '../../FilesizeField';
import BetterURLField from '../../../components/BetterURLField';

export default ({
  record,
  ...props
}) => (
  <ReferenceManyField
    addLabel={false}
    reference="devices"
    target="earner"
    pagination={<Pagination />}
    perPage={25}
    sort={{
      field: 'createdAt',
      order: 'DESC',
    }}
    record={record}
    {...props}
  >
    <Datagrid>
      <TextField source="identifier" sortable={false} />
      <BetterURLField
        valSource="ip"
        refSource="ipInfoURL"
        label="IP"
        sortable={false}
      />
      <FilesizeField source="bandwidthSold" label="Bandwidth Sold" />
      <DateField source="updatedAt" label="Last Seen" />
    </Datagrid>
  </ReferenceManyField>
);
