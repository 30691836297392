import React from 'react';
import {
  List, Datagrid, Pagination, NumberField, TextField, DateField, Filter, SelectInput,
} from 'react-admin';
import CurrencyField from '../CurrencyField';
import BulkActionButtons from './BulkActionButtons';
import { getRowStyle } from './styles';

const PayoutsPagination = (props) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

const F = ({ ...props }) => (
  <Filter {...props} variant="standard">
    <SelectInput
      source="status"
      allowEmpty
      alwaysOn
      label="Status"
      choices={[
        { id: 'rejected', name: 'Rejected' },
        { id: 'pending', name: 'Pending' },
        { id: 'paid', name: 'Paid' },
        { id: 'processing', name: 'Processing' },
        { id: 'approved', name: 'Approved' },
      ]}
    />
  </Filter>
);

export default ({ ...props }) => (
  <List
    {...props}
    bulkActionButtons={<BulkActionButtons />}
    sort={{ field: 'createdAt', order: 'ASC' }}
    filters={<F />}
    pagination={<PayoutsPagination />}
    perPage={25}
  >
    <Datagrid rowStyle={getRowStyle}>
      <NumberField sortable source="id" />
      <DateField source="createdAt" label="CreatedAt" addLabel />
      <TextField source="payoutStatus" label="Status" addLabel />
      <TextField source="user.handle" label="User" addLabel />
      <TextField source="user.paypalEmail" label="Payout Email" addLabel />
      <TextField source="team.name" label="Team" addLabel />
      <CurrencyField label="Amount" source="amount" />
    </Datagrid>
  </List>
);
